@import url("~leaflet/dist/leaflet.css");

.leaflet-container {
    width: 100%;
    height: 500px;
}

/*thead {*/
/*    background-color: #ed1b2e;*/
/*    color: azure;*/
/*}*/

/*tr:nth-child(even) {*/
/*    background-color: #ccc0c0;*/
/*}*/

/*tr:hover:not(.unhovarable) {*/
/*    background-color: #2a2c7e;*/
/*    color: white*/
/*}*/
img{
    border: 4px solid red
}
/*table:not(.diaspora-table) {*/
/*    color: #2a2c7e;*/
/*}*/

.navbar {
    background-color: #ed1b2e
}

.logo {
    width: 40px;
    height: 40px;
}

/*body, html {*/
/*    background-color: #f0f0f0;*/
/*    min-height: 100vh;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

.container {
    margin-top: 60px;
}

.content {
    margin: auto;
    width: 60%;
    /* border: 3px solid darkblue; */
    padding: 10px;
}

ul:not(.diaspora-list) {
    list-style: square inside url("assets/images/logo_nav.png");
}

ul li {
    margin: 0;
    padding: 0;
}

p {
    font-size: 18px;
    color: #2a2c7e
}

Button {
    background-color: #ed1b2e;
    color: #fff;
    width: 50%;
    height: 50px;
}

.w3-bar {
    width: 100%;
    padding-top: 5px;
    border-radius: 25px;
    border-color: #2a2c7e;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    vertical-align: middle;
}

.cameras {
    margin-left: auto;
    margin-right: auto;
    position: -webkit-sticky;
}

@media (max-width: 320px) {
    .unhovarable {
        font-size: 11px;
    }
    .diaspora-requirements-list {
        font-size: 13px;
    }
}

@media (max-width: 575.98px) {
    .content {
        margin: auto;
        width: 100%;
        /* border: 3px solid darkblue; */
        padding: 0px;
    }

    .diaspora-button-links {
        display: inline;
    }

    .unhovarable {
        font-size: 11px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .content {
        margin: auto;
        width: 100%;
        /* border: 3px solid darkblue; */
        padding: 10px;
    }

    .diaspora-button-links {
        display: inline;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .content {
        margin: auto;
        width: 100%;
        /* border: 3px solid darkblue; */
        padding: 10px;
    }

    .diaspora-button-links {
        display: inline;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .content {
        margin: auto;
        width: 60%;
        /* border: 3px solid darkblue; */
        padding: 10px;
    }

    .content-table {
        width: 80%;
    }

    .diaspora-button-links {
        display: flex;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .content {
        margin: auto;
        width: 40%;
        /* border: 3px solid darkblue; */
        padding: 10px;
    }

    .content-table {
        width: 60%;
    }

    .diaspora-button-links {
        display: flex;
    }
}

.input-radio {
    width: 100%;
    padding-top: 5px;
    border-radius: 25px;
    border-color: #0000A0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.input-radio:hover {
    background-color: #ed1b2e;
}

.input-radio {
    background-color: #ed1b2e;
}

.header-icon {
    text-align: justify;
    font-weight: bold;
    font-size: 30px;
    margin-left: 5px
}

.header-back {
    text-align: justify;
    font-weight: bold;
    font-size: 20px;
    margin-left: 3px
}

.relative {
    position: relative;
    border: 3px solid green;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.w3-class-label {
    margin-right: auto;
    font-size: 20px;
    padding-top: 13px;
}

.w3-class-icon {
    margin-left: auto;
    margin: 0px;
    padding: 0px;
    font-size: 57px
}

select {
    height: 50px;
}

a:visited {
    color: rgb(90, 49, 140);
}

a:link {
    color: #0f9d58;
}

h1.diaspora-heading {
    font-family: 'sans-serif';
    margin: 100px auto;
    text-align: center;
    font-size: 30px;
    max-width: 600px;
    position: relative;
}

h1.diaspora-heading:before {
    content: "";
    display: block;
    width: 130px;
    height: 5px;
    background: #2b357c;
    left: 0;
    top: 50%;
    position: absolute;
}

h1.diaspora-heading:after {
    content: "";
    display: block;
    width: 130px;
    height: 5px;
    background: #2b357c;
    right: 0;
    top: 50%;
    position: absolute;
}

.btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
  }

  /* Green */
  .success {
    border-color: #04AA6D;
    color: green;
  }

  .success:hover {
    background-color: #04AA6D;
    color: white;
  }

  /* Blue */
  .info {
    border-color: #2196F3;
    color: dodgerblue
  }

  .info:hover {
    background: #2196F3;
    color: white;
  }

  /* Orange */
  .warning {
    border-color: #ff9800;
    color: orange;
  }

  .warning:hover {
    background: #ff9800;
    color: white;
  }

  /* Red */
  .danger {
    border-color: #2a2c7e;
    color: #ed1b2e;
  }

  .danger:hover {
    background: #ed1b2e;
    color: white;
  }

  /* Gray */
  .default {
    border-color: #e7e7e7;
    color: black;
  }

  .default:hover {
    background: #e7e7e7;
  }

