body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global CSS file or CSS module */
.phone-input-container {
  width: 100%;
  border: 2px solid #2b357c;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.phone-input-container input {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
}

.phone-input-container input:focus {
  border-color: #ed1b2e;
  box-shadow: 0 0 5px rgba(237, 27, 46, 0.5);
}
